import React, {Component} from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Dialog from "./components/Dialog";
import SectionOpening from "./components/SectionOpening";
import SectionOpeningNews from "./components/SectionOpeningNews";
import SectionOpeningservice from "./components/SectionOpeningservice";
import SectionPerfectShapedUnderwearEffect from "./components/SectionPerfectShapedUnderwearEffect";
import SectionPerfectShapedUnderwearEffect2 from "./components/SectionPerfectShapedUnderwearEffect2";
import SectionFreeConsultation from "./components/SectionFreeConsultation";
import SectionQuestionAndAnswer from "./components/SectionQuestionAndAnswer";
import SectionShowOne from "./components/SectionShowOne";
import SectionShowTwo from "./components/SectionShowTwo";
import SectionShowThree from "./components/SectionShowThree";
import SectionShowReview from "./components/SectionShowReview";
import SectionShowDianPing from "./components/SectionShowDianPing";
import SectionPartThree1 from "./components/SectionPartThree1";
import SectionPartThree2 from "./components/SectionPartThree2";
import SectionPartThree3 from "./components/SectionPartThree3";
import SectionFocus from "./components/SectionFocus";
import SectionHistory from "./components/SectionHistory";
import SectionTrouble from "./components/SectionTrouble";
import SectionReason1 from "./components/SectionReason1";
import SectionReason2 from "./components/SectionReason2";
import SectionMakePlan from "./components/SectionMakePlan";
import SectionIdealSolution from "./components/SectionIdealSolution";
import SectionGallery from "./components/SectionGallery";
import SectionProductIntroduction from "./components/SectionProductIntroduction";
import SectionLast from "./components/SectionLast";
import SectionList from "./components/SectionList";
import SectionBackTop from "./components/SectionBackTop";
// import SectionWomenDay from "./components/SectionWomenDay";
import SectionBanner from "./components/SectionBanner";
import SectionReadMore1 from "./components/ReadMore_1";
import SectionReadMore4 from "./components/ReadMore_4";

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialog: false,
            dialogCodeUrl: "",
        };

        this.handleGotoJapanese = this.handleGotoJapanese.bind(this);
        this.handleCall = this.handleCall.bind(this);
        this.handleShowDialog = this.handleShowDialog.bind(this);
        this.handleHideDialog = this.handleHideDialog.bind(this);

    }

    handleGotoJapanese() {
        window.open("http://www.maruko.cn/");
    }

    // handleGotoJapanese() {
    // 	window.open("https://www.maruko.com");
    // }

    handleCall() {
        window.open("callto:021-52653211");
    }

    handleShowDialog(codeUrl) {
        const body = document.getElementsByTagName("body")[0];
        if (!body.classList.contains("show-dialog")) {
            body.classList.add("show-dialog");
            this.setState({dialog: true, dialogCodeUrl: codeUrl});
        }
    }

    handleHideDialog() {
        const body = document.getElementsByTagName("body")[0];
        body.classList.remove("show-dialog");
        this.setState({dialog: false});
    }



    render() {
        return (
            <>
                {
                    this.state.dialog && (
                        <Dialog onHide={this.handleHideDialog} codeUrl={this.state.dialogCodeUrl}/>
                    )
                }
                <Header onShowDialog={this.handleShowDialog} onGotoJapanese={this.handleGotoJapanese}/>
                {/*<SectionWomenDay/>*/}
                {/*<SectionBanner/>*/}
                <SectionOpening/>
                <SectionFocus/>
                <SectionOpeningNews/>
                <SectionTrouble/>
                <SectionOpeningservice/>
                <SectionHistory/>
                <SectionReadMore1/>
                <SectionReason1/>
                <SectionReason2/>
                <SectionShowOne />
                <SectionBackTop />
                <SectionPerfectShapedUnderwearEffect/>
                <SectionPerfectShapedUnderwearEffect2/>
                <SectionShowTwo />
                <SectionShowThree/>
                <SectionShowReview/>
                <SectionShowDianPing/>
                <SectionPartThree1/>
                <SectionPartThree2/>
                <SectionPartThree3/>
                <SectionFreeConsultation/>
                <SectionReadMore4/>
                <SectionMakePlan/>
                <SectionIdealSolution/>
                <SectionQuestionAndAnswer/>
                <SectionList/>
                <SectionGallery/>
                <SectionProductIntroduction/>
                <SectionLast/>
                <Footer onShowDialog={this.handleShowDialog} />

            </>
        );
    }

};
