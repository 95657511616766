import React from "react";
import "./index.scss";
import Read_More from "./images/read_more-3.png";

const RedirectUrl = "https://mp.weixin.qq.com/s/lj2EAooa639kz6TVT0TKNw";
const Target = "_blank";
export default () => {
  return (
    <section className="section-show-review">
      <div className="container">
        <div className="row">
          <div className="column">
            <div className="wrapper-level-1">
              <div className="star">
                <div className="txt">
                  <p><span>会有多大的变化？</span></p>
                  <p>听听使用者的心声吧！</p>
                </div>
              </div>
              <div className="view _1">
                <div className="word">
                  <p>最初的一个星期稍微有点小勒的感觉。即便如此，因为并不难受所以还是一直穿着。结果，在这一个星期的时间里，大腿围竟然减了3cm左右！！明明体重完全没有变化，但身材却真真实实地变好了，觉得很不可思议。</p>
                  <div className="name">深圳市 林小姐（30岁左右）</div>
                </div>
              </div>
              <div className="view _2">
                <div className="word">
                  <p>生完孩子之后变得很胖，我妈妈实在看不下去了，就介绍日本的梦魅力MARUKO给我。我从学生时代起下半身就一直比较瘦，但胖了之后，大腿并拢后都没有间隙了。</p>
                  <p>坦率讲，最开始我觉得价格有点小贵，还要每天都穿，有点小烦，但真正开始穿了之后，就习惯了，而且体型有了很大的变化之后，觉得付出的一切都值得。现在我已经穿了四年了。</p>
                  <p>如今，我的体型已经和生孩子前一样了，以前有点自卑的腿也不再在意了。</p>
                  <div className="name">
                    上海市 陆小姐（25岁左右）
                  </div>
                </div>
              </div>
              <div className="view _3">
                <div className="word">
                  <p> 因为臀部和大腿偏粗，明明上半身只有s码，下半身却要L码。自从遇见了MARUKO，相比20岁的时候胸围还要大4cm！腰围和臀围都有所减少。</p>
                  <p>
                    虽然已经接近40岁了，但接下来才要享受反抗年龄和重力作用♪
                  </p>
                  <div className="name">
                    日本札幌市 高田小姐（40岁左右）
                  </div>
                </div>
              </div>
              <div className="view _4">
                <div className="word">
                  <p> 穿MARUKO已经有15年了，期间怀孕生产2次，因为有MARUKO在完全不用担心会身材走形。随着年龄增长越来越庆幸自己认识了MARUKO，即使是不易瘦的体质也能逐步恢复理想体型！希望大家都能来认识一下MARUKO的神奇</p>
                  <div className="name">
                    日本新潟市 松井小姐（40岁左右）
                  </div>
                </div>
              </div>
              <div className="note">
                <div className="txt">
                  <div>*以上是个人的使用感受及评价。</div>
                  <div>*效果因人而异，并非所有人的穿着效果都一样。</div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="read_more-list">
          <a href={RedirectUrl} target={Target}>
            <img src={Read_More} alt=""/>
          </a>
        </div>
      </div>

    </section>
  );
};
