import React from "react";
import "./index.scss";
import trouble1 from "./trouble_1.png"
import person1 from "./t_per1.png"
import person2 from "./t_per2.png"
import person3 from "./t_per3.png"
import product1 from "./t_pic1.png"
import product2 from "./t_pic2.png"
import product3 from "./t_pic3.png"
import pink from "./t_pink.png"
import yellow from "./t_yellow.png"
import red from "./t_red.png"
export default () => {
	return (
		<section className="Section-Trouble">
			<div className="container_add">
				<div className="">
					{/* part1 */}
					<div className=""><img src={trouble1} alt=""/></div>
					{/* part2 */}
					<div className="t_part2">
						<div className="problem">
							<div className="float">
								<div className="product1"><img src={product1} alt=""/></div>
								<div className="person person1"><img src={person1} alt=""/></div>
							</div>
							<div className="text text1">如果您胸围，臀围都基本符合平均值，但腰围较高，那么您就需要进行全身线条的调整；</div>
						</div>
						<div className="problem ">
							<div className="float">
								<div className="person person2"><img src={person2} alt=""/></div>
								<div className="product2"><img src={product2} alt=""/></div>
											
							</div>
							<div className="text text2">如果您胸部底围以及腰围数据偏大，那么您需要以上半身的产品为中心，支撑胸部并收紧腰腹部；</div>
						</div>
						<div className="problem ">
							<div className="float">
								<div className="product3"><img src={product3} alt=""/></div>
								<div className="person person3"><img src={person3} alt=""/></div>
							</div>
							<div className="text text3">如果您臀围以及腿围数据偏大，那么您需要进行下半身的塑形，调整臀部以及腿部线条。</div>
						</div>
						<div className="down"><img src={pink} alt=""/></div>
					</div>
					<div  className="t_part3">
						<p className="check">自我check ！</p>
						<div className="shape">
							<div className="title">
								<p>自从过了25岁，</p>	
								<span className="border">这些身形烦恼都来了！？</span>	
							</div>
							<div className="list">
								<p>背部、腋下肉肉逐渐堆积</p>
								<p>内裤过紧留下勒痕</p>
								<p>腰部出现游泳圈</p>
								<p>很难找到合身的内裤</p>
								<p>无法穿贴身的衣裙</p>
							</div>
						</div>
						<div className="attention">
							<p>如果有一点说中就要引起注意了！</p>
							<p>是否合体的内衣将大幅决定您的体型</p>
							<p>穿着适合自己的内衣</p>
							<p>5年后10年后会更加明显</p>
						</div>
						<div className="down"><img src={red} alt=""/></div>
					</div>			
				</div>
			</div>
		</section>
	);
};
