import React from "react";
import Brand from "../Brand";
import { WeChat, Weibo, Home, QrCode } from "../Icon";
import Xiaohongshu from "../img/xiaohongshu.png";
import qrWechat from "../img/qr-wechat.png"
import qrWeibo from "../img/qr-weibo.png"
import qrXiaohongshu from "../img/qr-xiaohongshu.png"
import "./index.scss";

export default ({
	onShowDialog,
	onGotoJapanese,
}) => {
	return (
		<header className="section-header" id='activity1'>
			<div className="container_add">
				<div className="row break-md">
					<div className="column width-auto">
						<h1>
							<a href="javascript:void(0);"><Brand color="#C30D23" /></a>
						</h1>
					</div>
					<div className="column">
						<ul>
							<li><a href="javascript:void(0);"><i aria-hidden="true"><WeChat /></i>微信<i aria-hidden="true"><img className="qr_img" src={qrWechat} alt="pic"/></i></a></li>
							<li><a href="javascript:void(0);"><i aria-hidden="true"><Weibo /></i>微博<i aria-hidden="true"><img className="qr_img" src={qrWeibo} alt="pic"/></i></a></li>
							<li><a href="javascript:void(0);"><i className="icon_img" aria-hidden="true"><img src={Xiaohongshu} /></i>小红书<i aria-hidden="true"><img className="qr_img" src={qrXiaohongshu} alt="pic"/></i></a></li>
							{/* <li><a href="#"><i className="dianping" aria-hidden="true"><img src={require('../Footer/dianping.png')} /></i>店铺<i aria-hidden="true"><img className="wecode " src={require('../Footer/qr-dianpu.png')} /></i></a></li> */}
							{/* <li><a href="#" onClick={e => { e.preventDefault(); onShowDialog(require("../Footer/qr-weibo.png")); }}><i aria-hidden="true"><Weibo /></i>官方微博<i aria-hidden="true"><QrCode /></i></a></li> */}
							{/* <li><a href="#" onClick={e => { e.preventDefault(); onGotoJapanese(); }}><i aria-hidden="true"><Home /></i><span>关于MARUKO</span></a></li> */}
							{/* <li><a href="#" onClick={e => { e.preventDefault(); onGotoJapanese(); }}><i aria-hidden="true"><Home /></i><span>日本官网</span></a></li> */}
						</ul>
					</div>
				</div>
			</div>
		</header>
	);
};
