import React from "react";
import "./index.scss";

export default () => {
    return (
        <section className="section-show-three">
            <div className="container_add">
                <div className="row">
                    <div className="column">
                        <div className="wrapper-level-1">
                            <div className="text">
                                <div className="sty1 _1">
                                    <p>将身体上的赘肉调整到正确的位置，</p>
                                    <p>打造出理想的身材线条！</p>
                                </div>
                                <div className="sty2 _1">
                                    「理想塑形女性内衣」
                                </div>
                                <div className="sty1 _2">
                                    能保持住塑形后的身材。
                                </div>
                                <div className="sty2 _2">
                                    一直持续穿着也能调整身形，
                                </div>
                                <div className="sty1 _3">
                                    身材曲线会有明显变化！
                                </div>
                                <div className="sty3">
                                    <p>免费咨询</p>
                                    <p>理想塑形活动实施中！</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
