import React from "react";
import "./index.scss";
import icon1 from "./h_icon1.png";
import icon2 from "./h_icon2.png";
import icon3 from "./h_icon3.png";

export default () => {
	return (
		<section className="section-history">
			<div className="">
				<div className="container_add">
					<div className="h_title">
						<p>日本MARUKO自1978年在日本创立，至今已有40余年的专业经验。</p>
						<p>多年来一直为女性更加美丽而努力，不懈地开发高品质的产品。 </p>
					</div>
					<div className="h_ul">
						<div className="h_li">
							<div className="h_top">
								<div className="t_left"><img src={icon1} alt=""/></div>
								<div className="t_right">
									<p className="point"><span>Point 1</span></p> 
									<p className="point_text">贴心的服务</p>
								</div>
							</div>
							<p className="t_bottom">作为专为女性服务的MARUKO，从服务开始为无数女性实现了美，借助多年来引以为傲的工艺和技术，使女性在不同年龄段都能够绽放光彩。</p>
						</div>	
					</div>
					<div className="h_ul">
						<div className="h_li">
							<div className="h_top">
								<div className="t_left"><img src={icon2} alt=""/></div>
								<div className="t_right">
									<p className="point"><span>Point 2</span></p> 
									<p className="point_text">出色的效果</p>
								</div>
							</div>
							<p className="t_bottom">MARUKO塑形内衣多年来持续追踪顾客的穿着数据，从购买到售后MARUKO都提供免费的调拨服务，从各方面帮助广大女性调整身形。</p>
						</div>	
					</div>
					<div className="h_ul">
						<div className="h_li">
							<div className="h_top">
								<div className="t_left"><img src={icon3} alt=""/></div>
								<div className="t_right">
									<p className="point"><span>Point 3</span></p> 
									<p className="point_text">完善的售后</p>
								</div>
							</div>
							<p className="t_bottom">MARUKO会员都可以返店重新测量身体数据重新调拨，或是咨询在塑形中遇到的问题，解决穿不好不会穿的后顾之忧。</p>
							<p className="t_bottom">MARUKO创立了别具特色的调整型内衣方法、标准和技术，并开设了顾客咨询中心，始终如一地服务于顾客。</p>
							

						</div>	
					</div>
				</div>
			</div>
			
		</section>
		
	);
};
