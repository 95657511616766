import React from "react";
import "./index.scss";
import href from "./href.png";

export default () => {
	return (
		<section className="section-back-top">
		<div className="backup">
			<a href="#activity1"  className="href"><img src={href} alt="pic"/></a>
		</div>
		
		
		</section>
	);
};
