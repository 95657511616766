import React from "react";
import "./index.scss";
import logo from "./logo1.png"
import logo_red from "./red_logo1.png"
import red from "./red.png"
import role from "./role.png"
import Mrole from "./m_role.jpg"

export default () => {
    return (
        <section className="section-show-dianPing">
            <div className="dianping ">
                <div className="dianping_logo">
                    <img src={logo} alt=""/>
                </div>
            </div>
            <div className="container_add role">
                <img src={role} alt=""/>
            </div>
            <div className="container_add Mrole">
                <img src={Mrole} alt=""/>
            </div>
            <div className="xiaohongshu">
                <div className="xiaohongshu_logo">
                    <img src={logo_red} alt=""/>
                </div>
                
            </div>
            <div className="container_add red">
                <img src={red} alt=""/>
            </div>
        </section>
    );
};
