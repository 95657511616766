import React from "react";

export default () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.8 25">
			<rect fill="#FFF" width="24.8" height="25" rx="5" />
			<path fill="#FFF" fillRule="evenodd" d="M3.14,15.84c0,2.55,3.33,4.63,7.43,4.63S18,18.39,18,15.84s-3.33-4.63-7.44-4.63S3.14,13.28,3.14,15.84Z" />
			<path fill="#A32D29" fillRule="evenodd" d="M18,12.17c-.31-.09-.52-.16-.36-.56a2.28,2.28,0,0,0,0-2.19c-.71-1-2.66-1-4.9,0,0,0-.7.3-.52-.25A2.52,2.52,0,0,0,12,6.58c-1.21-1.21-4.44,0-7.2,2.81-2.07,2.07-3.28,4.27-3.28,6.16,0,3.63,4.66,5.84,9.21,5.84,6,0,9.94-3.47,9.94-6.22a3.33,3.33,0,0,0-2.66-3Zm-7.26,7.92c-3.64.36-6.77-1.28-7-3.67s2.52-4.61,6.15-5,6.77,1.28,7,3.67S14.38,19.73,10.75,20.09Z" />
			<path fill="#B9801E" fillRule="evenodd" d="M22,5.53a5.8,5.8,0,0,0-5.53-1.79h0a.83.83,0,0,0-.65,1,.84.84,0,0,0,1,.65,4.13,4.13,0,0,1,4.79,5.32h0a.84.84,0,0,0,1.6.51h0A5.79,5.79,0,0,0,22,5.53Z" />
			<path fill="#B9801E" fillRule="evenodd" d="M19.76,7.53a2.83,2.83,0,0,0-2.69-.88.73.73,0,1,0,.3,1.42h0a1.38,1.38,0,0,1,1.32.42A1.4,1.4,0,0,1,19,9.85h0a.72.72,0,1,0,1.37.44A2.77,2.77,0,0,0,19.76,7.53Z" />
			<path fill="#060000" fillRule="evenodd" d="M11.11,13.29a4,4,0,0,0-4.44,1.93A2.7,2.7,0,0,0,8.4,19.06a3.91,3.91,0,0,0,4.68-2,2.71,2.71,0,0,0-2-3.79Zm-1.32,4a1.37,1.37,0,0,1-1.67.55,1,1,0,0,1-.37-1.45,1.37,1.37,0,0,1,1.63-.55,1,1,0,0,1,.41,1.45ZM11,15.77a.53.53,0,0,1-.63.23.36.36,0,0,1-.16-.55.52.52,0,0,1,.61-.23A.37.37,0,0,1,11,15.77Z" />
		</svg>
	);
};
