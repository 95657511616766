import React from "react";
import "./index.scss";
import pic from "./pic.jpg";
import line from "./line.png";

export default () => {
	return (
		<section className="section-make-plan">
			<div className="container_add">
				<div className="row">
					<div className="column">
						<div className="wrapper-level-1">
							<p className="text1">根据体型分析给每个人定制最适合的计划！</p>
							<div className="line">
								<img src={line} alt="pic"/>
							</div>
							<p className="text2">至今已有41年的专业经验，<br></br>在日本拥有<span>210</span>家直营门店。</p>
							<div className="shops">
								<img src={pic} alt="pic"/>
							</div>

						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
