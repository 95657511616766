import React from "react";
import "./index.scss";

export default () => {
	return (
		<section className="section-perfect-shaped-underwear-effect2">
			<div className="container_add">
				<div className="row" aria-hidden="true">
					<div className="column">
						<table>
							<tr><td /><td /></tr>
						</table>
					</div>
				</div>
				<div className="row" aria-hidden="true">
					<div className="column">
						<div className="wrapper-level-1 _1">
							<table>
								<tr><td /><td /></tr>
							</table>
							<p>将腋下赘肉紧缩至胸围部，不仅如此，还能将胸围聚拢上提，胸部更显挺拔，展现完美曲线。</p>
						</div>
					</div>
				</div>
				<div className="row" aria-hidden="true">
					<div className="column">
						<div className="wrapper-level-1 _2">
							<table>
								<tr><td /><td /></tr>
							</table>
							<p>腋下赘肉聚拢至胸部，后背也变挺直，不仅副乳被收进胸部，背部的“游泳圈”也不见啦，呈现完美的身体曲线！</p>
						</div>
					</div>
				</div>
				<div className="row" aria-hidden="true">
					<div className="column">
						<div className="wrapper-level-1 _3">
							<table>
								<tr><td /><td /></tr>
							</table>
							<p>赶走小腹周围的赘肉，和游泳圈说“Byebye”，腰部更加纤细，轻松收紧赘肉，显露S线条。</p>
						</div>
					</div>
				</div>
				<div className="row" aria-hidden="true">
					<div className="column">
						<div className="wrapper-level-1 _4">
							<table>
								<tr><td /><td /></tr>
							</table>
							<p>抹平小腹，臀部上提，完成全身体型调整，呈现美丽S型纤体。</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="column">
						<div className="wrapper-level-1">
							<aside>效果因人而异，并非所有人都是一样的效果。</aside>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
