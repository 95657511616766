import React from "react";
import "./index.scss";
export default () => {
    return (
        <section className="section-perfect-shaped-underwear-effect">
            <div className="container_add">
                <div className="row">
                    <div className="column top">
                        <div className="text">
                            <p>梦魅力MARUKO</p>
                            <p>【完美塑形女性内衣】</p>
                            <p><span>穿着效果</span></p>
                        </div>
                    </div>
                </div>
               </div>
        </section>
    );
};
