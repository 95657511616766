import React from "react";
import Read_More from "./images/read_more-2.png";
import "./index.scss";

const RedirectUrl = "https://mp.weixin.qq.com/s/7eljL7UI9dKa7Nguj5nxKw";
const Target = "_blank";
export default () => {
  return (
    <section className="section-show-two">
      <div className="wrapper">
        <div className="icon_pic">
          <div className="container">
            <div className="wrapper-level-1">
              <div className="text">
                <div className="title"><span>用证据与事实说话！</span></div>
                <div className="box">
                  <div className="num num1">
                    <div className="txt">
                      <div className="tit">以构造力学为基准的体型修整力！</div>
                      <div>梦魅力MARUKO的「理想塑形女性内衣」是设计师以构造力学为基准的科学的设计方案，结合技术精湛的制作师专业创造出来的，此项构造力学的设计在学术上也给予高度评价。</div>
                    </div>
                  </div>
                  <div className="num num2">
                    <div className="txt">
                      <div className="tit">穿对了内衣比不穿更舒服更凉快</div>
                      <div>而且MARUKO所用面料拥有吸水、吸汗&速干功能，不仅更加透气，在高温闷热的天气越穿越舒服，越穿越凉快！足够亲肤，温和的面料也不怕会对娇嫩的肌肤带来过多摩擦，不在担心炎热的夏日应该如何塑形！</div>
                    </div>
                  </div>
                  <div className="num num3">
                    <div className="txt">
                      <div className="tit">穿戴正确尺码的内衣不仅仅是为了体型</div>
                      <div>穿戴尺寸正确的内衣不仅有利于乳房的血液循环避免乳腺相关的疾病，在运动时可以减轻身体负担，冬天还可防止寒风钻入肌肤而受凉。每位女性，从青春发育期乳房基本定型时开始，除了在床上睡觉时，应该养成每天戴胸罩的习惯，无论春夏秋冬，还是产后哺乳，都要坚持每天穿戴。这样，不但有助于健美，而且还有利于健康和胸部的保养。</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="read_more-list">
          <a href={RedirectUrl} target={Target}>
            <img src={Read_More} alt=""/>
          </a>
        </div>
      </div>
    </section>
  );
};
