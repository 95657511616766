import React from "react";
import "./index.scss";
import pic1 from "./pic1.jpg";
import pic2 from "./pic2.jpg";
import pic3 from "./pic3.jpg";
import pic4 from "./pic4.jpg";
import pic5 from "./pic5.jpg";


export default () => {
	return (
		<section className="section-product-introduct">
			<div className="title">产 品 介 绍</div>
			<div className="container_add">
				<div className="part">
					<p className="p_title">塑形优秀</p>
					<div className="p_main">
						<div className="p_text">
							<p className="top">3D立体钢圈</p>
							<p className="bottom">MARUKO的塑形内衣采用黑科技3D立体钢圈，不仅更加贴合胸部下方，不会有压胸感，而且在18℃以上就会恢复原来的形状，勿需担心多次清洗后会变形。</p>
						</div>
						<div className="p_pic"><img src={pic1} alt="pic"/></div>
					</div>
					<div className="p_main">
						<div className="p_pic"><img src={pic2} alt="pic"/></div>
						<div className="p_text">
							<p className="top">梯度施压</p>
							<p className="bottom">MARUKO在一块面料中对应的不同穿着部位，也有细致的压力区分（soft·medium·hard），在内衣内侧还采用不同厚度的面料，产生外侧与下侧紧、内侧适中、上侧松的效果，将胸部牢牢托起并且向内侧靠</p>
						</div>
						
					</div>
				</div>
				<div className="part">
					<p className="p_title">穿着舒适</p>
					<div className="p_main">
						<div className="p_text">
							<p className="top">丝绸混纺面料</p>
							<p className="bottom">MARUKO在面料中混入丝绸，更加柔软亲肤，每天都可以放心穿着！将塑形内衣本有的不悦感一扫而光！</p>
						</div>
						<div className="p_pic"><img src={pic3} alt="pic"/></div>
					</div>
					<div className="p_main">
						<div className="p_pic"><img src={pic4} alt="pic"/></div>
						<div className="p_text">
							<p className="top">伸缩性·支撑性均优越的施压面料</p>
							<p className="bottom">MARUKO使用了经·纬·斜方向都可以柔和延展的「Torisukin」面料，支撑力表现优越，而且网格状的面料十分透气。</p>
						</div>
						
					</div>
				</div>
				<div className="part">
					<p className="p_title">设计好看</p>
					<div className="p_main">
						<div className="p_text">
							<p className="top">不影响外衣穿着的原创蕾丝</p>
							<p className="bottom">为了不影响外衣的穿着，MARUKO捻合细线，织就了比普通蕾丝还要轻薄的面料。迎合不同系列的主题，设计了不同的蕾丝花样，在塑形的同时，也没有忘记美。</p>
						</div>
						<div className="p_pic"><img src={pic5} alt="pic"/></div>
					</div>
					
				</div>
			</div>
		</section>
	);
};
