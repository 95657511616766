import React from "react";

export default () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.83 25">
			<path fill="#468038" fillRule="evenodd" d="M3.82,0H21a3.83,3.83,0,0,1,3.82,3.82V21.18A3.83,3.83,0,0,1,21,25H3.82A3.83,3.83,0,0,1,0,21.18V3.82A3.83,3.83,0,0,1,3.82,0Z" />
			<path fill="#FFF" fillRule="evenodd" d=" M8.38,4c3.18-.44,7.82,1.47,8.48,5.15h0c-3.79-.65-8.2,3.29-6.78,7.37a10.56,10.56,0,0,1-3.29-.34L4.2,17.49l.74-2.23C-.35,11.77,1.93,4.72,8.38,4Zm6.19,8.47a.8.8,0,0,0-.74.74.79.79,0,0,0,.74.74.84.84,0,0,0,.93-.74.85.85,0,0,0-.93-.74Zm4.08,0a.8.8,0,0,0-.74.74.79.79,0,0,0,.74.74.85.85,0,0,0,.93-.74.85.85,0,0,0-.93-.74ZM7,7.11A1,1,0,0,0,5.86,8,1,1,0,0,0,7,9,.87.87,0,0,0,7.9,8,.86.86,0,0,0,7,7.11ZM12.16,9A.88.88,0,0,0,13.09,8a.87.87,0,0,0-.93-.92A1,1,0,0,0,11.05,8,1,1,0,0,0,12.16,9ZM12,11.26c4.85-5,16.08,1.84,8.36,7.9.18.57.36,1.19.56,1.85l-2-1.12C14.14,21.84,7,16.4,12,11.26Z" />
		</svg>
	);
};
