import React from "react";
import Brand from "../Brand";
import "./index.scss";
export default () => {
    return (
        <section className="section-part-three1">
            <div className="container_add">
                <div className="row">
                    <div className="column">
                        <div className="wrapper-level-1 main">
                            <div className="logo">
                                <Brand color="#C30D23" />
                            </div>

                            <div className="box">
                                <div className="text">
                                    <div className="sty1">
                                        拥有理想的身材线条的秘密
                                    </div>
                                    <div className="sty2">
                                        <p>梦魅力MARUKO</p>
                                        <p>身材塑形师</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
