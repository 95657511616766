import React from "react";
import "./index.scss";
import per1 from "./per1.jpg";
import per2 from "./per2.jpg";
import per3 from "./per3.jpg";
import per4 from "./per4.jpg";
import per5 from "./per5.jpg";
import per6 from "./per6.jpg";
import per7 from "./per7.jpg";
import per8 from "./per8.jpg";
import top from "./top.png";
import bottom from "./bottom.png";

export default () => {
	return (
		<section className="section-ideal-solution">
			<div className="container_add">
				<div className="part1">
					<div className="part_list"><img src={per1} alt="pic"/></div>
					<div className="part_list"><img src={per2} alt="pic"/></div>
					<div className="part_list"><img src={per3} alt="pic"/></div>
					<div className="part_list"><img src={per4} alt="pic"/></div>
					<div className="part_list text">
						<p>累计约60万人的</p>
						<p>资料为基准，</p>
						<p>为身材美化</p>
						<p>提供理想方案！</p>
					</div>
					<div className="part_list"><img src={per5} alt="pic"/></div>
					<div className="part_list"><img src={per6} alt="pic"/></div>
					<div className="part_list"><img src={per7} alt="pic"/></div>
					<div className="part_list"><img src={per8} alt="pic"/></div>
				</div>
				
				<p className="note">每位女性身体上的赘肉都有差异性，因此梦魅力MARUKO的身材塑形会根据各人所能达到的「理想身材」，进行身材美化的计划。</p>
				<div className="part2">
					<div className="line"><img src={top} alt=""/></div>
					<p className="title1">有任何烦恼或者不安，疑问</p>
					<p className="title2">都可以向我们咨询！</p>
					<div className="line"><img src={bottom} alt=""/></div>
					<div className="text2">
						<p>梦魅力MARUKO的<span>专业塑形师</span>会根据您的理想提供课程计划。</p>
						<p>无法轻易向他人倾诉的<span>身材走样的烦恼、对完美体型的追求和希望</span>，都可以都向我们咨询！</p>
						<p>关于<span>「理想塑形女性内衣」</span>的正确<span>穿着方式</span>和<span>保养方法</span>，专业塑形师会全面协助您进行了解，试穿，购买商品后，也会定期为您提供贴心的售后服务。</p>
						<p>和我们一起，向美丽的身材线条努力吧！</p>
					</div>
				</div>
			</div>
		</section>
	);
};
