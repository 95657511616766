import React from "react";
import "./index.scss";
export default () => {
    return (
        <section className="section-part-three3">
            <div className="container_add">
                <div className="title">
                    <p>首先检测一下自己的身材比例！</p>
                    <p>第一次在梦魅力MARUKO体验</p>
                </div>
                <div className="main">
                    <div className="text">
                        <p>梦魅力MARUKO对第一次来的客人进行免费咨询和免费试穿体验。身材塑形师配合您的体型，进行身材塑形！</p>
                        <p>第一次的梦魅力MARUKO体验大约为90分钟。身材塑形师会仔细地向每一位客人提出建议。因为是女性限定沙龙，所以请放心。</p>
                    </div>
                    <p className="note">※男性不能进店。</p>
                </div>
               
            </div>
        </section>
    );
};
