import React from "react";
import "./index.scss";

export default () => {
	return (
		<section className="section-last">
			<div className="container_add">
				<div className="main">
					<p>让我们在MARUKO相聚</p>
					<p>让更多的人实现变美的愿望</p>
				</div>
			</div>
		</section>
	);
};
