import React from "react";
import Brand from "../Brand";
import "./index.scss";
export default () => {
	return (
		<section className="Section-Opening-service">
			<div className="container_add">
				<div className="row">
					<div className="column">
						<div className="wrapper-level-1">
							<div className="sop_logo"><Brand color="#C30D23" /></div>
							<div className="pro_service">
								<div className="title">
									<p>MARUKO塑形内衣，</p>
									<p>更贴心，更专业，更舒适</p>
								</div>
								<div className="left">
									<p>美化<br/>身体的<br/>内衣</p>
									<p className="small">温馨专业日式服务</p>
								</div>
								
							</div>
							<div className="text">
								<p>选择MARUKO</p>
								<p>实现理想体型的理由</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
