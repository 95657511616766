import React from "react";
import "./index.scss";
import per1 from "./per1.jpg";
import per2 from "./per2.jpg";
import per3 from "./per3.jpg";
import per4 from "./per4.jpg";
import per5 from "./per5.jpg";
import per6 from "./per6.jpg";
import per7 from "./per7.jpg";
import per8 from "./per8.jpg";
import per9 from "./per9.jpg";
import per10 from "./per10.jpg";
import per11 from "./per11.jpg";
import per12 from "./per12.jpg";

export default () => {
	return (
		<section className="section-gallery">
			<div className="container_add">
				<div className="part">
					<p className="text"><span>GALLERY</span></p>
					<p className="line"></p>
				</div>
				
				<div className="pic">
					<div className="part_list"><img src={per1} alt="pic"/></div>
					<div className="part_list"><img src={per2} alt="pic"/></div>
					<div className="part_list"><img src={per3} alt="pic"/></div>
					<div className="part_list"><img src={per4} alt="pic"/></div>
					<div className="part_list"><img src={per5} alt="pic"/></div>
					<div className="part_list"><img src={per6} alt="pic"/></div>
					<div className="part_list"><img src={per7} alt="pic"/></div>
					<div className="part_list"><img src={per8} alt="pic"/></div>
					<div className="part_list"><img src={per9} alt="pic"/></div>
					<div className="part_list"><img src={per10} alt="pic"/></div>
					<div className="part_list"><img src={per11} alt="pic"/></div>
					<div className="part_list"><img src={per12} alt="pic"/></div>
				</div>
				
			</div>
		</section>
	);
};
