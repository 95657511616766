import React from "react";
import "./index.scss";
import line from "./line.png";
export default () => {
    return (
        <section className="section-part-three2">
            <div className="container_add">
                <div className="row">
                    <div className="column">
                        <div className="wrapper-level-1">
                            <div className="box">
                                <div className="top">
                                    <div className="title">
                                       挑选适合你的尺寸！
                                    </div>
                                    <div className="title2">
                                        「理想塑形女性内衣」尺寸齐全！
                                    </div>

                                    <div className="text">
                                        梦魅力MARUKO「理想塑形女性内衣」设计了更多的尺寸，为不同体型的女性提供了更适合的尺寸。无论是胸部偏小，臀部偏大或是其他身形烦恼都不用担心，经过MARUKO的人工+机器测量，能够找到合身的内衣。例如，MARUKO现有胸罩标准尺寸×罩杯尺寸有30款。全身套装现有尺寸和半定制尺寸共准备了1330款！每个人身材存在差异性，梦魅力MARUKO如此齐全的尺寸，可以让您感受量身定制适合舒适的产品体验！
                                    </div>
                                </div>
                                
                                <div className="tables">
                                    <div className="th">
                                        塑形内衣：根据不同的罩杯，一共准备了18个标准尺寸<span>（短款、普款、长款）</span>。
                                    </div>
                                    <div className="tr">
                                        <div className="td">A,B,C,D,E,F</div>
                                        <div className="td">65~85</div>
                                    </div>
                                </div>
                                <div className="tables">
                                    <div className="th">
                                        文胸：根据不同罩杯，一共准备了112个标准尺寸。
                                    </div>
                                    <div className="tr">
                                        <div className="td">A,B,C,D,E,F</div>
                                        <div className="td">65~125</div>
                                    </div>
                                </div>
                                <div className="tables">
                                    <div className="th">
                                        内裤：一共准备了13个尺寸！
                                    </div>
                                    <div className="tr">
                                        <div className="td">SS~9L</div>
                                    </div>
                                </div>

                                <p className="note">*根据不同系列项目有可能存在缺货情况。</p>
                                <div className="bottom">
                                    <div className="contain">
                                        <div className="title">
                                            <p>理想塑形女性内衣的</p>
                                            <p>设计以及缝纫工艺</p>
                                        </div>
                                        <div className="line">
                                            <img src={line} alt=""/>
                                        </div>
                                        <div className="text">
                                            <p>内衣每天都要贴身穿着，细节更体现品质。</p>
                                            <p>追求出众的线条感，舒适的穿着感，</p>
                                            <p>以及特别的设计性。</p>
                                            <p>梦魅力MARUKO与众不同的“讲究”</p>
                                        </div>
                                    </div>

                                </div>
                            
                            
                            
                            
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
