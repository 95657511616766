import React from "react";
import "./index.scss";
import pic1 from "./images/pic1.png";
import pic2 from "./images/pic2.png";
import pic3 from "./images/pic3.png";
import pic4 from "./images/pic4.png";
import pic5 from "./images/pic5.png";
import pic6 from "./images/pic6.png";
import title1 from "./images/title1.png";
import title2 from "./images/title2.png";
import title3 from "./images/title3.png";
import title4 from "./images/title4.png";
import title5 from "./images/title5.png";
import title6 from "./images/title6.png";

export default () => {
  return (
    <section className="section-list">
      <div className="title">塑 形 内 衣 系 列</div>
      <div className="part part1">
        <div className="box">
          <div className="container_add">
            <div className="top right">
              <div><img src={title1} alt=""/></div>
              <p>蕾丝花样以橄榄叶为灵感，系列正如其名Curvaceous，代表了「曲线美」，创造出女性优美的身体曲线！</p>
            </div>
            <div className="pic"><img src={pic1} alt=""/></div>
          </div>
        </div>
      </div>
      <div className="part part2">
        <div className="box ">
          <div className="container_add">
            <div className="top">
              <div className="t_title"><img src={title2} alt=""/></div>
              <p>樱花的蕾丝花样，代表着属于熟龄女性的美，MARUKO希望每一个女性都能像樱花一样，成为温柔呵护大家，吸引大家聚在一起的让人憧憬的女性。</p>
            </div>
            <div className="pic"><img src={pic2} alt=""/></div>
          </div>
        </div>
      </div>
      <div className="part part3">
        <div className="box">
          <div className="container_add">
            <div className="top right">
              <div className="t_title"><img src={title3} alt=""/></div>
              <p>在特别的日子送给特别的你，肩带可拆卸&冬暖夏凉的面料让美丽的新娘轻松驾驭婚礼现场，在人生中的重要时刻留下闪亮身影。</p>
            </div>
            <div className="pic"><img src={pic3} alt=""/></div>
          </div>
        </div>
      </div>
      <div className="part part4">
        <div className="box">
          <div className="container_add">
            <div className="top">
              <div className="t_title"><img src={title4} alt=""/></div>
              <p>露蜜艾斯系列采用极光丝织就蕾丝花样，随着角度变化颜色也发生改变，在面料中MARUKO还细心地加入辅酶Q，提高皮肤细胞活性，让女性比光更加闪耀。</p>
            </div>
            <div className="pic"><img src={pic4} alt=""/></div>
          </div>
        </div>
      </div>
      <div className="part part5">
        <div className="box">
          <div className="container_add">
            <div className="top">
              <div className="t_title"><img src={title5} alt=""/></div>
              <p>更加简明易懂的产品线，使用利巴蕾丝，是适合新人尝试的入门系列。</p>
            </div>
            <div className="pic"><img src={pic5} alt=""/></div>
          </div>
        </div>
      </div>
      <div className="part part6">
        <div className="box">
          <div className="container_add">
            <div className="top">
              <div className="t_title right"><img src={title6} alt=""/></div>
              <p>为初次穿着MARUKO的客人而设计，希望与MARUKO的相遇让您变得更加美好。</p>
            </div>
            <div className="pic"><img src={pic6} alt=""/></div>
          </div>
        </div>
      </div>
    </section>
  );
};
