import React from "react";
import "./index.scss";
import news_title from "./images/news_title.png"
import news1 from "./images/news.20220308.jpg"
import news2 from "./images/news.20220305.jpg"
import news3 from "./images/news.20220228.jpg"
import news4 from "./images/news.20211224.jpg"

const Article_Link = {
  'article1': {
    'url': 'https://mp.weixin.qq.com/s/YuAzRKkSOB_oL0nuw4d4Fw',
    'time': '8 Mar 2022',
    'img': news1,
  },
  'article2': {
    'url': 'https://mp.weixin.qq.com/s/zhay4bwk6uEmKd9ovJrXBg',
    'time': '5 Mar 2022',
    'img': news2,
  },
  'article3': {
    'url': 'https://mp.weixin.qq.com/s/dD-EybOPz4tHLEv8Z3YlBg',
    'time': '28 Feb 2022',
    'img': news3,
  },
  'article4': {
    'url': 'https://mp.weixin.qq.com/s/B_h_VP24tcppJaafwerffg',
    'time': '24 Nov 2021',
    'img': news4,
  },
};

export default () => {
  return (
    <section className="section-opening-news">
      <div className="container_add">
        <div className="row">
          <div className="column">
            <div className="wrapper-level-1">
              <div className="news_header">
                <div className="news_title"><img src={news_title} alt=""/></div>
              </div>
              <div className="news_ul">
                <div className="news_list">
                  <a href={Article_Link['article1']['url']} target="_blank">
                    <div><img src={Article_Link['article1']['img']} alt=""/></div>
                    <div className="more">{Article_Link['article1']['time']}</div>
                  </a>
                </div>
                <div className="news_list">
                  <a href={Article_Link['article2']['url']} target="_blank">
                    <div><img src={Article_Link['article2']['img']} alt=""/></div>
                    <div className="more">{Article_Link['article2']['time']}</div>
                  </a>
                </div>
                <div className="news_list">
                  <a href={Article_Link['article3']['url']} target="_blank">
                    <div><img src={Article_Link['article3']['img']} alt=""/></div>
                    <div className="more">{Article_Link['article3']['time']}</div>
                  </a>
                </div>
                <div className="news_list">
                  <a href={Article_Link['article4']['url']} target="_blank">
                    <div><img src={Article_Link['article4']['img']} alt=""/></div>
                    <div className="more">{Article_Link['article4']['time']}</div>
                  </a>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
