import React from "react";
import "./index.scss";
import { WeChat, Weibo } from "../Icon";
import Xiaohongshu from "../img/xiaohongshu.png";
import qrWechat from "../img/qr-wechat.png"
import qrWeibo from "../img/qr-weibo.png"
import qrXiaohongshu from "../img/qr-xiaohongshu.png"
import Focus from "./focus.png";
import contact from "./contact.png";
export default () => {
	return (
		<section className="section-focus">
			<div className="">
				<div className="container_add">
					<div className="box">
						<div className="focus_img"><img src={Focus} alt=""/></div>
						<div className="qr">
							<div className="qr_part">
								<div><img src={qrWechat} alt=""/></div>
								<h2 className="wechat"><i><WeChat /></i><span>官方微信公众号</span></h2>
								
							</div>
							<div className="qr_part">
								<div><img src={qrWeibo} alt=""/></div>
								<h2 className="weibo"><i><Weibo /></i><span>官方微博</span></h2>
								
							</div>
							<div className="qr_part">
								<div><img src={qrXiaohongshu} alt=""/></div>
								<h2 className="xiaohongshu"><i><img src={Xiaohongshu} /></i><span>小红书官方号</span></h2>
								
							</div>
						</div>
						<div className="contact">
							<div><span className="join">大中国区 FC加盟招商中！</span></div>
							<div>
								<p>联系方式：</p>
								<p>MARUKO大阪本社 海外事业组</p>
								<a itemProp="email" href="mailto:r-izumi@marukonet.jp"><span><img src={contact} alt=""/></span><span>r-izumi@marukonet.jp</span></a>
								<a itemProp="email" href="mailto:info@maruko.cn"><span><img src={contact} alt=""/></span><span>info@maruko.cn</span> </a>
								{/* <a itemProp="email" href="mailto:r-izumi@marukonet.jp">r-izumi@marukonet.jp</a> */}
								{/* <a itemProp="email" href="mailto:info@maruko.cn">info@maruko.cn </a> */}
							</div>
							
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
	);
};
