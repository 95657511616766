import React from "react";
import "./index.scss";
import Read_More from "./images/read_more-4.png";

const RedirectUrl = "https://mp.weixin.qq.com/s/meCgmamFIRP1xnJD8kTypw";
const Target = "_blank";
export default () => {
  return (
    <section className="section-read-more-4">
      <div className="container">
        <div className="read_more-list">
          <a href={RedirectUrl} target={Target}>
            <img src={Read_More} alt=""/>
          </a>
        </div>
      </div>
    </section>
  );
}



