import React from "react";
import "./index.scss";
import r2_pic1 from "./r2_pic1.png";

export default () => {
	return (
		<section className="section-reason2">
			<div className="">
				<div className="container_add">
					<div className="title">
						<span>理</span>
						<span>由</span>
						<span>2</span>
					</div>
					<div className="box">
						<h1>免费的产品体验以及完善的售后服务</h1>
						<p>由店内专业塑形师从测量数据到售后全程服务，成为帮助您逐步接近理想身形的良师益友。关于「理想塑形女性内衣」的正确穿着方式和保养方法，专业塑形师会全面协助您进行了解。</p>
						<p>和我们一起向美丽的身材线条努力吧！</p>
						<div><img src={r2_pic1} alt=""/></div>
					</div>
					
				</div>
			</div>
			
		</section>
		
	);
};
