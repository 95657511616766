import React from "react";
// import Brand from "../Brand";
import "./index.scss";
import part1 from "./part_1.png"
import part1_bg from "./part_1_bg.jpg"
import part2 from "./part_2.png"
import part2_bg from "./part_2_bg.jpg"
import part3 from "./part_3.png"
import part3_bg from "./part_3_bg.jpg"

export default () => {
    return (
        <section className="section-show-one">
            <div className="container_add">
                <div className="row">
                    <div className="column">
                        <div className="wrapper-level-1">
                            <div className="header">
                                <div className="brand">
                                    <p>MARUKO</p>
                                </div>
                                <div className="name">
                                    <p>「理想塑形女性内衣」</p>
                                </div>
                                <div className="text">
                                    <p>讲究品质与设计</p>
                                    <p>不同年龄不同体型的人</p>
                                    <p>也能拥有理想身材曲线！</p>
                                    <p>丰胸美背，瘦腰翘臀</p>
                                    <p> 体型变化一目了然！</p>
                                </div>
                            </div>
                            <div className="part part1">
                                <div className="content">
                                    <div className="title">
                                        <p>更加贴合，更加舒适</p>
                                    </div>
                                    <div className="text">
                                        MARUKO所使用面料不仅
                                        <span>轻薄透气</span>
                                        ，而且
                                        <span>弹性优良</span>
                                        ，即使每天穿着也不会有紧勒感。
                                        此外通过
                                        <span>立体设计</span>
                                        ，在身体的不同部位之间采用了
                                        <span>分离构造</span>
                                        ，更加贴合身体。只有局部有动作时，一般不会对塑形内衣的贴合性产生影响，更不会导致变形走形。
                                    </div>
                                </div>
                                <div className="back">
                                    <img src={part1_bg} alt=""/>
                                </div>
                            </div>
                            <div className="part part2">
                                <div className="content">
                                    <div className="title-top">
                                        <p>仅靠体温</p>
                                    </div>
                                    <div className="title">
                                        <p>就可恢复原状的立体钢圈</p>
                                    </div>
                                    <div className="text">
                                        MARUKO使用了靠体温就可以恢复原状的
                                        <span>原创记忆合金钢圈</span>
                                        ，即使经过多次洗涤，也不用担心会破坏内衣形状，保证了胸部的支撑效果。
                                        <p className="note">※该功能未覆盖全系列</p>
                                    </div>
                                </div>
                                <div className="back">
                                    <img src={part2_bg} alt=""/>
                                </div>
                            </div>
                            <div className="part part3">
                                <div className="content">
                                    <div className="title">
                                        <p>使用利巴蕾丝打造高级设计</p>
                                    </div>
                                    <div className="text">
                                        MARUKO同样注重
                                        <span>蕾丝与色彩</span>
                                        的使用，针对不同的系列概念，设计出不同的蕾丝图样。此外较普通蕾丝更薄的编织方式使得塑形内衣
                                        <span>足够轻薄</span>
                                        ，不会影响到外衣的穿着。
                                        <div className="note">
                                            <p>※咖碧史系列，露蜜艾斯系列，樱花系列均有使用。</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="back">
                                    <img src={part3_bg} alt=""/>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
