import React from "react";
import "./index.scss";
import r1_pic1 from "./r1_pic1.jpg";

export default () => {
	return (
		<section className="section-reason1">
			<div className="">
				<div className="container_add">
					<div className="title">
						<span>理</span>
						<span>由</span>
						<span>1</span>
					</div>
					<div className="box">
						<div className="part1">
							<p className="text1">梦魅力MARUKO的独自计测技术：</p>
							<p className="text2">3D立体测量</p>
						</div>
						<div className="part2">
							<p>梦魅力MARUKO的黑科技3D立体测量，精准测量，试穿调整型内衣前后的身材变化。</p>
							<p>3D、360°分享身材比例及体型的变化，并提供完善的售后服务。购买、穿着一段时间后回店进行3D测量，不断跟踪记录您体型变化的数据，直观地前后对比让您了解到穿MARUKO内衣的有效性、必要性。</p>
						</div>
						<div className="part3">
							<img src={r1_pic1} alt=""/>
						</div>
					</div>
					
				</div>
			</div>
			
		</section>
		
	);
};
