import React from "react";
import "./index.scss";

export default ({
	codeUrl,
	onHide,
}) => {
	return (
		<section className="dialog" onClick={onHide}>
			<div className="qrcode">
				<button onClick={onHide}></button>
				<img src={codeUrl} alt="" />
			</div>
		</section>
	);
};
