import React from "react";
import Brand from "../Brand";
import { WeChat, Weibo } from "../Icon";
import Xiaohongshu from "../img/xiaohongshu.png";
import qrWechat from "../img/qr-wechat.png";
import qrWeibo from "../img/qr-weibo.png";
import qrXiaohongshu from "../img/qr-xiaohongshu.png";
import per1 from "./per1.png";
import href from "./href.png";
import "./index.scss";

export default ({
	onShowDialog,
	
}) => {  
	return (
		<footer className="section-footer">
			<div className="container_add">
				<div className="main">
					<div className="left"><img src={per1} alt="pic"/></div>
					<div className="right">
						<h1><i><Brand color="#C30D23" /></i></h1>
						<address>
							<strong>公司名称</strong><br />玛露珂尔（上海）国际贸易有限公司<br />
							<strong>主营业务</strong><br />销售女性调整型内衣<br />
						</address>
						<div className="qr">
							<div className="qr_part">
								<div><img src={qrWechat} alt=""/></div>
								<h2 className="wechat"><i><WeChat /></i><span>官方微信公众号</span></h2>
								
							</div>
							<div className="qr_part">
								<div><img src={qrWeibo} alt=""/></div>
								<h2 className="weibo"><i><Weibo /></i><span>官方微博</span></h2>
								
							</div>
							<div className="qr_part">
								<div><img src={qrXiaohongshu} alt=""/></div>
								<h2 className="xiaohongshu"><i><img src={Xiaohongshu} /></i><span>小红书官方号</span></h2>
								
							</div>
						</div>
					</div>
					<a href="#activity1"  className="href"><img src={href} alt="pic"/></a>
				</div>
				
				{/* 20200515 */}
				<div className="record">
						<div>© 2020-现在 marukonet.cn 版权所有</div>
						<div><a href="https://beian.miit.gov.cn" target="_blank" className="link">沪ICP备15004871号-2</a></div>
						<div>
							<img src={require('../Footer/gongan.png')} />
							<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602005505" target="_blank" className="link">
								沪公网安备 31010602005505号
								</a>
						</div>

				</div>
			</div>
			



		</footer>
	);
};
