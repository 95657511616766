import React from "react";
import "./index.scss";
import num1 from "./num1.png";
import num2 from "./num2.png";
import num3 from "./num3.png";
import num4 from "./num4.png";
import num5 from "./num5.png";
import pic1 from "./pic1.jpg";
import pic2 from "./pic2.jpg";

export default () => {
	return (
		<section className="section-free-consultation">
			<div className="container_add">
				<div className="row">
					<div className="column">
						<div className="wrapper-level-1">
							<ol>
								<li>
									<i aria-hidden="true">
										<img src={num1} alt=""/>
									</i>
									<div>
										<p>预约来店</p>
										<p>请留下您的电话号码进行预约~</p>
									</div>
								</li>
								<li>
									<i aria-hidden="true">
										<img src={num2} alt=""/>
									</i>
									<div>
										<p>专家咨询</p>
										<p>说出您对身材的烦恼，以及理想的身材体型。</p>
									</div>
								</li>
								<li>
									<i aria-hidden="true">
										<img src={num3} alt=""/>
									</i>
									<div>
										<p>内衣诊断</p>
										<p>内衣和身体线条的关系，体型变形的原因等，介绍有关于美的知识。</p>
									</div>
								</li>
								<li>
									<i aria-hidden="true">
										<img src={num4} alt=""/>
									</i>
									<div>
										<p>私密试穿</p>
										<p>裸体量尺，试穿，专业塑形师会为您打造理想身材。</p>
									</div>
								</li>
								<li>
									<i aria-hidden="true">
										<img src={num5} alt=""/>
									</i>
									<div>
										<p>量身定制个性方案</p>
										<p>为您推荐合适的套装组合和专业的美丽塑形计划。</p>
									</div>
								</li>
							</ol>
						</div>
						<div className="wrapper-level-2">
							<div><img src={pic1} alt="pic"/></div>
							<div><img src={pic2} alt="pic"/></div>
						</div>
						<div className="wrapper-level-3">
							<h1>售后服务辅助你完善塑形计划的重要环节。</h1>
							<p>购买梦魅力MARUKO产品后，我们的专业塑形师会制定全面的塑形计划，并定期回店提供免费的3D测量和专业的穿着方法指导。您在穿着中的任何疑问都可以得到我们贴心专业的回复。</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
