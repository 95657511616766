import React from "react";
import "./index.scss";
import title1 from "./title1.png";

export default () => {
	return (
		<section className="section-question-and-answer">
			<div className="part part1">
				<div className="title title1">
					<div className="container_add">
						<div><img src={title1} alt=""/></div>
					</div>
				</div>
				<div className="main main1">
					<div className="container_add">
						<ul>
							<li>
								<div  className="question pic">
									<p>只是想去体验试穿一下，一定要购买商品吗？</p>
								</div>
								
								<div className="answer pic">
									<p className="fon_weight">对初次光临本店的顾客，我们原则不做产品推销。</p>
									<p className>试穿体验也是在听取了客人的烦恼和要求的基础上进行的，主要是让客人了解梦魅力MARUKO。不会向没有购买意愿的顾客做强硬推销，即便仅仅是为试穿而光临本店，也请保持轻松愉悦的心情。</p>
								</div>
							</li>
							<li>
								<div  className="question pic">
									<p>听说试穿体验需要裸体量尺寸，不可以穿着衣服量吗？</p>
								</div>
								
								<div className="answer pic">
									<p>这与购买戒指时戴手套量尺寸是一个道理，穿上衣服测量的尺寸可能没那么准确。我们在测量时，客人只穿内裤，专业塑形师会使用测量背心、内衣、腰围等专业卷尺进行测量，从而为您提供专业正确的尺寸建议。</p>
								</div>
							</li>
							<li>
								<div  className="question pic">
									<p>试穿体验大概会花多长时间呢？</p>
								</div>
								
								<div className="answer pic small">
									<p>包含咨询的时间在内，大概需要90分钟。</p>
								</div>
							</li>
							<li>
								<div  className="question pic">
									<p>当天对衣服和随身携带的东西有要求吗？</p>
								</div>
								
								<div className="answer pic small">
									<p>没有特别的要求，如果穿紧身服装来本店的话，可以更真实地感受到和平时所穿内衣的不同</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="part part2">
				<div className="title title2">
					<div className="container_add">
						<p>常见的疑问</p>
					</div>
				</div>
				<div className="main main2">
					<div className="container_add">
						<ul>
							<li>
								<div  className="question pic">
									<p>购买商品后，一定要回店吗？</p>
								</div>
								
								<div className="answer pic">
									<p className>购买后，为了能让您充分了解到完美塑形女性内衣，给你身材带来的塑形变化，我们根据不同客人对身材变化的要求制定了专业合理的定期回店接受塑形服务的建议。所以期待您的回店。</p>
								</div>
							</li>
							<li>
								<div  className="question pic">
									<p>修整体形，下半身可以变瘦吗？</p>
								</div>
								
								<div className="answer pic">
									<p>如果你原本就有不错的曲线，修正体型后从视觉效果上来看你可能会很显瘦，但体重不会因为穿内衣而减轻。</p>
								</div>
							</li>
							<li>
								<div  className="question pic">
									<p>为什么一定要量尺寸和试穿呢？</p>
								</div>
								
								<div className="answer pic">
									<p>为了让您了解自己的真实尺寸，请一定要接受测量尺寸和试穿体验。每天都要贴身穿着的东西一定要选择没有任何不适及违和感的尺寸。另外，尺寸不合适的内衣被认为是破坏体型的原因之一。</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>


			
			
		</section>



		
	);
};
