import React from "react";
import Brand from "../Brand";
import "./index.scss";
import stores from "./stores.png";
export default () => {
	return (
		<section className="section-opening">
			<div className="wrapper">
				<div className="container_add">
					<div className="box">
						<div className="font_main part1">
							<div className="logo"><Brand color="#C30D23" /></div>
							<p className=""><span className="local bg_main font_white">日本本土老铺品牌</span></p>
							<p className="name font_weight">梦魅力MARUKO</p>
							<p className="focus">专注于研发生产塑形内衣产品</p>
						</div>
						<div className="part2">
							<div className="font_main">
								<p>梦魅力MARUKO</p>
								<p>专注研发生产</p>
								<p className="senior font_weight">高级塑形内衣产品</p>
								<p>让您更加接近理想体型</p>
								<p>一切都贵的有理由</p>
							</div>
							<div className="stores font_white bg_main">
								<p>MARUKO在日本已有<span className="num">210</span>家直营店</p>
								<p className="data">※2020年12月末</p>
								<div><img src={stores} alt=""/></div>
								<p>每一家店铺都有众多的女性粉丝</p>
								<p>是日本街头靓丽的风景线</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
	);
};
